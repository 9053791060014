<template>
    <div class="brands">
        <b-overlay
            :show="showLoader"
            variant="transperant"
            opacity="1"
            spinner-variant="primary"
        >

            <b-card
                no-body
                border-variant="success"
                class="shadow-none"
                v-for="invitation in invitations" :key="invitation.id"
            >
                <b-card-header class="pb-1">
                    <b-media no-body>
                        <b-media-aside class="mr-1">
                            <b-avatar
                                size="42"
                                :src="invitation.brand.image"
                            />
                        </b-media-aside>
                        <b-media-body>
                            <h5 class="mb-0">{{invitation.brand.name}}</h5>
                            <small class="text-muted">{{invitation.created_by.email}} Send you intivation to join his brand {{ invitation.brand.name }} as {{ invitation.permission }}</small>
                        </b-media-body>
                    </b-media>
                    <div class="demo-inline-spacing">
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-primary"
                            class="btn-icon ml-auto"
                            @click="updateInvitation(invitation.id, 'approve')"
                        >
                            Approved
                            <feather-icon icon="CheckIcon" />
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="outline-danger"
                            class="btn-icon ml-auto"
                            @click="updateInvitation(invitation.id, 'reject')"
                        >
                            Reject
                            <feather-icon icon="XIcon" />
                        </b-button>
                    </div>
                </b-card-header>
            </b-card>

            <b-card no-body>
                <b-card-header>
                    <b-card-title>Brands</b-card-title>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="primary"
                        class="btn-icon ml-auto"
                        @click="addModalShow = true"
                    >
                        Add New Brand
                        <feather-icon icon="PlusIcon" />
                    </b-button>
                </b-card-header>
                <div class="mb-2 mx-1">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col
                            cols="12"
                            md="8"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        >
                            <label>Show</label>
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block mx-50"
                            />
                            <label>entries</label>
                        </b-col>
                        <!-- Search -->
                        <b-col
                            cols="12"
                            md="4"
                        >
                            <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="filter"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                            />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col cols="12">
                        <b-table
                            hover
                            responsive
                            class="position-relative"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="brands"
                            :fields="fields"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection"
                            :filter="filter"
                            show-empty
                            empty-text="لم يتم العثور على سجلات مطابقة"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                        >
                            <template #cell(name)="data">
                                <b-media vertical-align="center">
                                    <template #aside>
                                        <b-avatar
                                            size="32"
                                            :src="data.item.image"
                                        />
                                    </template>
                                    <b-link
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.name }}
                                    </b-link>
                                    <small class="text-muted">@{{ data.item.signature }}</small>
                                </b-media>
                            </template>
                            <template #cell(permission)="data">
                                <b-badge :variant="data.item.permission == 'owner' ? 'light-success' : data.item.permission == 'admin' ? 'light-primary':'light-secondary'">
                                    {{data.item.permission}}
                                </b-badge>
                            </template>
                            <template #cell(timezone)="data">
                                <b-badge variant="primary">
                                    <feather-icon
                                        icon="ClockIcon"
                                        class="mr-25"
                                    />
                                    <span>{{data.item.timezone}}</span>
                                </b-badge>
                            </template>

                            <template #cell(created_at)="data">
                                <b-badge variant="primary">
                                    <feather-icon icon="ClockIcon" class="mr-25" />
                                    <span>{{ moment(data.item.created_at).format('llll') }}</span>
                                </b-badge>
                            </template>
                            
                            <template #cell(action)="data">
                                <div class="float-right">
                                    <template v-if="data.item.permission == 'owner'">
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-danger"
                                            class="btn-icon mr-1"
                                            @click="deleteBrand(data.item.id)"
                                        >
                                            <feather-icon icon="TrashIcon" />
                                        </b-button>
                                        <b-button
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-secondary"
                                            class="btn-icon"
                                            @click="editModal(data.item)"
                                        >
                                            <feather-icon icon="SettingsIcon" />
                                        </b-button>
                                    </template>
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-secondary"
                                        class="btn-icon ml-1"
                                        @click="setBrand(data.item)"
                                        v-if="data.item.id != $store.state.auth.user.current_brand"
                                    >
                                        <feather-icon icon="LogInIcon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col
                        cols="12"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            class="mb-0 mt-1 mt-sm-0"
                            pills
                            first-number
                            last-number
                            prev-class="next-item"
                            next-class="prev-item"
                        />
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>

        <!-- add modal -->
        <b-modal
            id="modal-2"
            ref="modal-add"
            v-model="addModalShow"
            title="Create Brand"
            ok-title="Send"
            cancel-title="Cancel"
            cancel-variant="outline-secondary"
            @hidden="resetAddModal"
            @ok="handleOkAddModal"
            no-close-on-backdrop
        >
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col cols="12">
                            <div class="d-flex justify-content-start align-items-center">
                                <div>
                                    <span v-if="brand.image.image_url != null" class="d-inline-block m1" @mouseenter="overlayImage=true" @mouseleave="overlayImage=false">
                                        <b-overlay opacity="1" variant="transperant" :show="overlayImage">
                                            <b-img
                                                height="75" width="75"
                                                :src="brand.image.image_url"
                                                class="d-inline-block mr-1 mb-1 m1 border"
                                                rounded
                                            />
                                            <template #overlay>
                                                <div class="text-center">
                                                    <b-button
                                                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                                        variant="flat-warning"
                                                        class="btn-icon mr-75 mb-75"
                                                        size="sm"
                                                        @click="changeImage"
                                                    >
                                                        <feather-icon icon="Edit2Icon" />
                                                    </b-button>
                                                </div>
                                            </template>
                                        </b-overlay>
                                    </span>
                                    <b-link @click="changeImage" v-else>
                                        <b-avatar
                                            rounded="sm"
                                            variant="white"
                                            size="75"
                                            class="d-inline-block mr-1 mb-1 popular"
                                        >
                                            <feather-icon icon="PlusIcon" class="text-primary" size="50" />
                                        </b-avatar>
                                    </b-link>
                                </div>
                                <div class="profile-user-info">
                                    <h6 class="mb-0">
                                    {{ brand.name }}
                                    </h6>
                                    <small class="text-muted">Please shoose brand image </small>
                                </div>
                            </div>

                            <div v-show="false">
                                <b-form-file
                                    v-model="brand.image.image"
                                    placeholder="Shoose image file"
                                    drop-placeholder="Drag & frop image file here.."
                                    browse-text="Browse"
                                    accept="image/*"
                                    ref="image"
                                    @input="createImageUrl"
                                />
                            </div>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Brand Name"
                                label-for="name"
                            >
                                <validation-provider
                                #default="{ errors }"
                                name="Brand Name"
                                rules="required"
                                >
                                <b-form-input
                                    v-model="brand.name"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Brand Name"
                                    id="name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Brand Signature"
                                label-for="name"
                            >
                                <validation-provider
                                #default="{ errors }"
                                name="Brand Signature"
                                rules="required"
                                >
                                <b-form-input
                                    v-model="brand.signature"
                                    :state="errors.length > 0 ? false:null"
                                    placeholder="Brand Signature"
                                    id="name"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group label="Time Zone">
                                <validation-provider
                                    #default="{ errors }"
                                    name="Time Zone"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="brand.timezone"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        :options="timezones"
                                        label="value"
                                        :reduce="item => item.key"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>

            <template #modal-footer>
                <b-button
                    variant="outline-secondary"
                    class="mr-1"
                    @click="resetAddModal"
                >
                Cancel
                </b-button>
                <b-button
                    variant="primary"
                    :disabled="loading"
                    @click="handleOkAddModal"
                    class="mr-1"
                >   
                    <template v-if="!loading">
                        Save
                    </template>
                    <template v-else>
                        <b-spinner small />
                    </template>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>


<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required} from '@validations'
import {
    BCard, BCardText, BRow, BCol, BButton, BAvatar, BLink, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm,
    BOverlay, BFormCheckbox, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup,
    BInputGroupAppend, BCardTitle, BCardHeader, BSpinner, BMediaAside, BMediaBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
export default {
    components:{
        ValidationProvider, ValidationObserver,
        BCard, BCardText, BRow, BCol, BButton, ToastificationContent,
        BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm, BOverlay, 
        BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
        BInputGroupAppend, BFormCheckbox, vSelect, BSpinner, BMediaAside, BMediaBody
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            overlayImage:false,
            addModalShow: false,
            loading: false,
            showLoader: true,
            perPage: 5,
            pageOptions: [5, 15, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [
                {key: 'name', label: 'name', sortable: true},
                {key: 'timezone', label: 'Time Zone', sortable: true},
                {key: 'permission', label: 'permission'},
                {key: 'created_at', label: 'created at'},
                {key: 'action', label: ''},
            ],
            brands: [],
            timezones: [],
            invitations: [],
            brand: {
                id: null,
                name: '',
                signature: '',
                timezone: null,
                image: {
                    image: null,
                    image_url: null
                }
            },
            required
        }
    },
    mounted(){
        this.getBrands()
        this.getTimezones()
        this.getInivitations()
    },
    methods: {
        changeImage(){
            console.log(this.$refs.image)
            this.$refs.image.$refs.input.click()
        },
        createImageUrl(){
            if (this.brand.image.image != null){
                this.brand.image.image_url = URL.createObjectURL(this.brand.image.image);
            }
        },
        editModal(brand){
            this.brand.id = brand.id
            this.brand.name = brand.name
            this.brand.signature = brand.signature
            this.brand.timezone = brand.timezone
            this.brand.image.image_url = brand.image
            this.brand.image.image = null
            this.addModalShow = true
        },
        handleOkAddModal(bvModalEvt){
            this.loading = true
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.submitForm()
        },
        async submitForm(){
            await this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    let formData = new FormData()
                    formData.append('name', this.brand.name)
                    formData.append('signature', this.brand.signature)
                    formData.append('timezone', this.brand.timezone)
                    if (this.brand.image.image != null){
                        formData.append('image', this.brand.image.image)
                    }
                    var action, endpoint;
                    if (this.brand.id != null){
                        endpoint = `/brands/${this.brand.id}/`
                        action = 'patch'
                    } else {
                        endpoint = '/brands/'
                        action = 'post'
                        formData.append('account', this.$store.state.auth.user.account.id)
                    }
                    axios[action](endpoint, formData, {headers:{"Content-Type": "multipart/form-data"}})
                    .then((response) => {
                        if (response.data.code == 400){
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Upgrade Plan!',
                                    icon: 'AlertCircleIcon',
                                    text: 'Brands quota exceeded, please upgrade plan.',
                                    variant: 'danger',
                                },
                            })
                            this.loading = false
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Brand Saved',
                                    icon: 'CheckIcon',
                                    text: 'Brand was saved successfully.',
                                    variant: 'success',
                                },
                            })
                            this.loading = false
                            this.addModalShow = false
                            this.resetAddModal()
                            this.getBrands()
                        }
                        console.log(response);
                    })
                    .catch((error) => {
                        this.loading = false
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Warning !',
                                icon: 'AlertCircleIcon',
                                text: 'Something Went Wrong.',
                                variant: 'danger',
                            },
                        })
                        JSON.stringify(error);
                    })
                } else {
                    setTimeout(() => this.loading=false, 500);
                }
            })
        },
        resetAddModal(){
            this.brand.id = null
            this.brand.name = ''
            this.brand.signature = ''
            this.brand.timezone = null
            this.brand.image.image_url = null
            this.brand.image.image = null
            this.addModalShow = false
        },
        deleteBrand(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    axios.delete(`/brands/${id}/`)
                    .then(response => {
                        console.log(response);
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'Your Brand has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                        this.getBrands()
                    })
                    .catch(error => {
                        console.log(error);
                    })
                }
            })
        },
        setBrand(brand){
            this.showLoader = true
            this.$store.commit('auth/setBrand', brand)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Brand set Succesfully',
                    icon: 'CheckIcon',
                    text: 'I do not think that word means what you think it means.',
                    variant: 'success',
                },
            })
            setTimeout(() => { this.showLoader = false }, 200);
        },
        async getBrands(){
            await axios.get("/brands")
            .then(response => {
                this.brands = response.data
                this.totalRows = response.data.length
                this.showLoader = false
            })
            .catch(error => {
                console.log(error);
            })
        },
        getTimezones(){
            axios.get("/brands/timezones")
            .then(response => {
                console.log(response);
                this.timezones = response.data
            })
            .catch(error => {
                console.log(error);
            })
        },
        getInivitations(){
            axios.get("/members/invitations")
            .then(response => {
                console.log(response);
                this.invitations = response.data
            })
            .catch(error => {
                console.log(error);
            })
        },
        updateInvitation(id, action){
            this.$bvModal
                .msgBoxConfirm(`Please confirm that you want to ${action} brand.`, {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if(value){
                        axios.put(`/members/${id}/${action}/`)
                        .then(response => {
                            console.log(response);
                            this.getBrands()
                            this.getInivitations()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Brand updated',
                                    icon: 'CheckIcon',
                                    text: 'Brand was updated Succesfully.',
                                    variant: 'success',
                                },
                            })
                        })
                        .catch(error => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Warning !',
                                    icon: 'AlertCircleIcon',
                                    text: 'Something Went Wrong.',
                                    variant: 'danger',
                                },
                            })
                            console.log(error);
                        })
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>
<style lang="scss">
    @import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style>
  .popular { border: 1px dashed var(--primary); }
</style>